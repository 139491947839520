import React, {useEffect, useState} from 'react';
import s from './ArrowsNavigationWrapper.scss';
import {IconButton} from 'wix-ui-tpa';
import {ChevronLeft, ChevronRight} from '@wix/wix-ui-icons-common/on-stage';
import {withGlobals} from '../../../../globalPropsContext';
import {withTranslations} from '@wix/wixstores-client-common-components/dist/es/src/outOfIframes/translations';
import {ISliderGlobalProps} from '../../../sliderGlobalStrategy';
import {useEnvironment, useExperiments} from '@wix/yoshi-flow-editor';
import classNames from 'classnames';
import {Experiments} from '../../../../constants';
import {useStylesParams} from '../../../../stylesParamsContext';
import {useStyles} from '@wix/tpa-settings/react';

export type ArrowsNavigationWrapperProps = {
  navigateNext(): void;
  navigatePrev(): void;
  arrowsContainerHeight?: number;
  children: React.ReactNode;
} & ISliderGlobalProps;

export enum ArrowsNavigationWrapperDataHook {
  Root = 'ArrowsNavigationWrapperDataHook.Root',
  LeftArrow = 'ArrowsNavigationWrapperDataHook.LeftArrow',
  LeftArrowContainer = 'ArrowsNavigationWrapperDataHook.LeftArrowContainer',
  RightArrow = 'ArrowsNavigationWrapperDataHook.RightArrow',
  RightArrowContainer = 'ArrowsNavigationWrapperDataHook.RightArrowContainer',
}

export const ArrowsNavigationWrapper: React.FC<ArrowsNavigationWrapperProps> = withGlobals(
  withTranslations()(
    ({children, globals, navigateNext, navigatePrev, arrowsContainerHeight}: ArrowsNavigationWrapperProps) => {
      const {sliderGalleryPreviousProduct, sliderGalleryNextProduct} = globals.textsMap;
      const {isRTL, isMobile} = useEnvironment();
      const {experiments} = useExperiments();

      const leftArrowLabel = isRTL ? sliderGalleryNextProduct : sliderGalleryPreviousProduct;
      const rightArrowLabel = isRTL ? sliderGalleryPreviousProduct : sliderGalleryNextProduct;

      const leftArrowClick = () => (isRTL ? navigateNext() : navigatePrev());
      const rightArrowClick = () => (isRTL ? navigatePrev() : navigateNext());
      const supportArrowsPlacement = experiments.enabled(Experiments.NewSliderArrowsDesignViewer);
      const [leftArrowVisibility, setLeftArrowVisibility] = useState<boolean>(false);
      const [rightArrowVisibility, setRightArrowVisibility] = useState<boolean>(false);

      const stylesParams = useStylesParams();
      const styles = useStyles();

      const shouldAlwaysShowArrows =
        isMobile || !styles.get(stylesParams.gallery_sliderShowArrowsOnHoverOnly) || !supportArrowsPlacement;

      useEffect(() => {
        if (shouldAlwaysShowArrows) {
          setBothArrowsVisibility(true);
        }
      }, [shouldAlwaysShowArrows]);

      /* istanbul ignore next: tested in sled */
      const inlineCssVars = {
        '--arrowsContainerHeight': arrowsContainerHeight ? `${arrowsContainerHeight}px` : 'unset',
      };

      const setBothArrowsVisibility = (visibility: boolean) => {
        setLeftArrowVisibility(visibility);
        setRightArrowVisibility(visibility);
      };

      const showOnlyLeftArrow = () => {
        setLeftArrowVisibility(true);
        setRightArrowVisibility(false);
      };

      const showOnlyRightArrow = () => {
        setLeftArrowVisibility(false);
        setRightArrowVisibility(true);
      };

      const onMouseOver = (event: React.MouseEvent<HTMLDivElement>) => {
        const bounds = event.currentTarget.getBoundingClientRect();
        const mouseX = event.clientX - bounds.left;
        if (mouseX > bounds.width / 2) {
          showOnlyRightArrow();
          return;
        }

        showOnlyLeftArrow();
      };

      const onMouseLeave = () => setBothArrowsVisibility(false);

      return (
        <div
          data-hook={ArrowsNavigationWrapperDataHook.Root}
          className={s.root}
          style={inlineCssVars as React.CSSProperties}
          {...(shouldAlwaysShowArrows ? {} : {onMouseOver, onMouseLeave})}>
          <div
            className={classNames(s.arrowContainer, s.left, {
              [s.supportArrowsPlacement]: supportArrowsPlacement,
              [s.visible]: leftArrowVisibility,
            })}
            data-hook={ArrowsNavigationWrapperDataHook.LeftArrowContainer}>
            <IconButton
              aria-label={leftArrowLabel}
              data-hook={ArrowsNavigationWrapperDataHook.LeftArrow}
              icon={<ChevronLeft />}
              onClick={leftArrowClick}
            />
          </div>
          <div className={classNames(s.sliderWrapper, {[s.supportArrowsPlacement]: supportArrowsPlacement})}>
            {children}
          </div>
          <div
            className={classNames(s.arrowContainer, s.right, {
              [s.supportArrowsPlacement]: supportArrowsPlacement,
              [s.visible]: rightArrowVisibility,
            })}
            data-hook={ArrowsNavigationWrapperDataHook.RightArrowContainer}>
            <IconButton
              aria-label={rightArrowLabel}
              data-hook={ArrowsNavigationWrapperDataHook.RightArrow}
              icon={<ChevronRight />}
              onClick={rightArrowClick}
            />
          </div>
        </div>
      );
    }
  )
);
